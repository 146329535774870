import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {FinMatchAccount} from '../models/finmatch-account.model';
import {ERROR_INTERCEPTOR_SKIP_HEADER} from '../interceptors/errorhandler.interceptor';

@Injectable({
    providedIn: 'root'
})
export class AccountRepository {
    constructor(private http: HttpClient,
                @Inject(APP_CONFIG) private config: AppConfig) {
    }

    getAccountData(): Observable<FinMatchAccount> {
        return this.http.get<FinMatchAccount>(this.config.API_URL + 'api/account')
            .pipe(take(1), map(acc => Object.assign(new FinMatchAccount(), acc)));
    }

    markTutorialDone() {
        this.http.post(`${this.config.API_URL}${this.config.API_URLs.account}/tutorial-done`, {});
    }

    save(account: any): Observable<HttpResponse<any>> {
        return this.http.post(this.config.API_URL + 'api/account', account, {observe: 'response'});
    }

    getAccountImage(accountId: string | number): Observable<string> {
        return this.http.get(this.config.API_URL + 'api/account/' + accountId + '/imageUrl', {headers: {[ERROR_INTERCEPTOR_SKIP_HEADER]: '1'}, responseType: 'text'});
    }
}
