import {FinMatchAccount, FinMatchAccountFactory} from '../../models/finmatch-account.model';
import {EventEmitter, Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import * as _ from 'lodash';
import {AppStateService} from '../root/app-state.service';
import {AuthServerProvider} from './auth-jwt.service';
import { HttpClient } from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {AccountRepository} from '../../repositories/account.repository';

@Injectable({providedIn: 'root'})
export class Principal {
    public tutorialCompleted$ = new EventEmitter<boolean>();
    private termsAccepted = false;
    private userIdentity: FinMatchAccount;
    private userIdentitySubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private authenticated = false;
    // private accountRetrievalInProgress = false;
    private authenticationState = new Subject<any>();

    constructor(
        private accountRepository: AccountRepository,
        private appState: AppStateService,
        private finMatchAccountFactory: FinMatchAccountFactory,
        public authJwtService: AuthServerProvider,
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig,
    ) {
    }

    getUserIdentitySubject(): BehaviorSubject<any> {
        return this.userIdentitySubject;
    }

    // getAccountRetrievalInProgress(): boolean {
    //     return this.accountRetrievalInProgress;
    // }

    setTutorialCompleted() {
        this.httpClient.post(`${this.config.API_URL}${this.config.API_URLs.account}/tutorial-done`, {})
            .subscribe();
        this.tutorialCompleted$.emit(true);
    }

    authenticate(identity: FinMatchAccount) {
        this.userIdentity = identity;
        this.userIdentitySubject.next(identity);
        this.authenticated = !!identity;
        this.termsAccepted = this.userIdentity && this.userIdentity.approvalTerms && this.userIdentity.privacyPolicy;
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        if (_.includes(authorities, 'ROLE_MASTER') && !this.userIdentity.isMaster()) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (authorities[i] !== 'ROLE_MASTER' && _.includes(this.userIdentity.authorities, authorities[i])) {
                return true;
            }
        }

        return false;
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(
            id => {
                return Promise.resolve(id.authorities && _.includes(id.authorities, authority));
            },
            () => {
                return Promise.resolve(false);
            }
        );
    }

    identity(force?: boolean): Promise<FinMatchAccount> {
        if (force === true) {
            this.userIdentity = undefined;
            this.userIdentitySubject.next(null);
        }

        if (!this.authJwtService.getToken()) {
            this.authenticate(null);
            return Promise.resolve(null);
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.fetchUserData();
    }

    fetchUserData(): Promise<FinMatchAccount> {
        this.appState.showGlobalSpinner();
        // this.accountRetrievalInProgress = true;
        return this.accountRepository
            .getAccountData()
            .toPromise()
            .then(account => {
                if (account) {
                    const accountInstance = this.finMatchAccountFactory.get(account);
                    this.authenticate(accountInstance);
                    this.termsAccepted =
                        this.userIdentity && this.userIdentity.approvalTerms && this.userIdentity.privacyPolicy;
                } else {
                    this.authenticate(null);
                    this.termsAccepted = false;
                }
                this.appState.hideGlobalSpinner();
                // this.accountRetrievalInProgress = false;
                return this.userIdentity;
            })
            .catch(err => {
                this.authenticate(null);
                // this.accountRetrievalInProgress = false;
                this.appState.hideGlobalSpinner();
                return null;
            });
    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    hasTermsAccepted(): any {
        return this.termsAccepted;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    getImageUrl(): string {
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
    }
}
