<div *ngIf="loggedUser !== undefined" class="error-page-container">
    <div class="error-page-denied-wrapper">
        <div class="error-message">
            <h1 *ngIf="!(errorType === '403' && !isAuthenticated())" class="error-code">{{!!errorType ? errorType : '???'}}</h1>

            <h2 *ngIf="errorType === '403' && isAuthenticated()" class="error-text" i18n>You do not have access to this page.</h2>
            <h2 *ngIf="errorType === '404'" class="error-text" i18n>Oops! This page could not be found.</h2>
            <h2 *ngIf="errorType !== '403' && errorType !== '404'" class="error-text" i18n>Oops! An error occured.</h2>

            <p *ngIf="errorType === '403' && !isAuthenticated()" class="error-text" i18n>
                Please log in before proceeding to the application.
            </p>
            <p *ngIf="errorType === '404'" class="error-description" i18n>
                Sorry but the page you are looking for does not exist, have been removed, name changed or is temporarily unavailable.
            </p>

            <div class="error-actions">
                <div *ngIf="errorType === '403' && !isAuthenticated()">
                    <a [routerLink]="'/login'" class="link-button link-button-bg" i18n>Login</a>
                </div>
                <div *ngIf="!isAuthenticated()">
                    <a [href]="config.landing_URL" class="link-button" i18n>Landing Page</a>
                </div>
                <div *ngIf="isAuthenticated()">
                    <a [routerLink]="'/start'" class="link-button" i18n>Start Page</a>
                </div>
            </div>
        </div>
        <div [hidden]="!errorMessage">
            <div class="alert alert-danger">{{errorMessage}}</div>
        </div>
    </div>
</div>
