import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {APP_CONFIG, AppConfig} from 'src/app/app-config.module';
import {Principal} from 'src/app/services/auth/principal.service';
import {FinMatchAccount} from '../../models/finmatch-account.model';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {
    errorMessage: string;
    errorType = '';

    public loggedUser: FinMatchAccount;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public principal: Principal,
        @Inject(APP_CONFIG) public config: AppConfig
    ) {
        this.principal.identity().then(account => {
            this.loggedUser = account;
        });
    }

    ngOnInit() {
        this.route.data.subscribe(routeData => {
            this.errorType = routeData.errorType;
            if (routeData.errorMessage) {
                this.errorMessage = routeData.errorMessage;
            }
        });
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }
}
